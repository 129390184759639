import { DateTime, Interval, Settings } from 'luxon';
import { dropLast, isEmpty, last } from 'ramda';

import { Locale } from '@cca/util';

import { OpeningTime } from '../opening-times';

import {
  OutletAddressUtil,
  OutletOpeningTimeEntryUtil,
  OutletOpeningTimeExceptionEntryUtil,
  OutletOpeningTimeGroupUtil,
} from './outlet.types';

export function concatenateAddress(address: OutletAddressUtil) {
  return `${address.street}${
    address.streetNumber ? ` ${address.streetNumber}` : ''
  }, ${address.zipCode} ${address.city}`;
}

export function getOpeningTimeExceptionValue(
  openingTimes: OutletOpeningTimeExceptionEntryUtil[],
  date: DateTime,
): string | undefined {
  return openingTimes?.find((openingTime) =>
    DateTime.fromSQL(openingTime.date)
      .startOf('day')
      .equals(date.startOf('day')),
  )?.description;
}

export function mapToOpeningTimes(
  openingTimes: OutletOpeningTimeEntryUtil[],
  timeSuffix: string,
  locale: Locale,
  timezone?: string,
): OpeningTime[] {
  return calculateOpeningTimeGroups(openingTimes).map((openingTimeGroup) => ({
    weekDays: mapToOpeningTimeWeekDays(openingTimeGroup, locale),
    duration: mapToOpeningTimeDuration(
      openingTimeGroup,
      timeSuffix,
      locale,
      timezone,
    ),
  }));
}

function calculateOpeningTimeGroups(
  openingTimeEntries: OutletOpeningTimeEntryUtil[],
): OutletOpeningTimeGroupUtil[] {
  return openingTimeEntries.reduce<OutletOpeningTimeGroupUtil[]>(
    (acc, current) => {
      const currentGroup = last(acc);
      if (
        !currentGroup ||
        currentGroup.openingTime !== current.openingTime ||
        currentGroup.closingTime !== current.closingTime
      ) {
        return [
          ...acc,
          {
            openingTime: current.openingTime,
            closingTime: current.closingTime,
            weekDays: [current.weekDay],
          },
        ];
      } else {
        return [
          ...dropLast(1, acc),
          {
            ...currentGroup,
            weekDays: [...currentGroup.weekDays, current.weekDay],
          },
        ];
      }
    },
    [],
  );
}

function mapToOpeningTimeDuration(
  openingTimeGroup: OutletOpeningTimeGroupUtil,
  timeSuffix: string,
  locale: Locale,
  timezone?: string,
): string {
  return `${toLocaleTimeString(
    openingTimeGroup.openingTime,
    locale,
    timezone,
  )} - ${toLocaleTimeString(openingTimeGroup.closingTime, locale, timezone)}${
    timeSuffix ? ` ${timeSuffix}` : ''
  }`;
}

function toLocaleTimeString(
  isoTime: string,
  locale: Locale,
  timezone?: string,
): string {
  return DateTime.fromISO(isoTime)
    .setLocale(locale)
    .setZone(timezone || Settings.defaultZone)
    .toLocaleString(DateTime.TIME_SIMPLE);
}

function mapToOpeningTimeWeekDays(
  openingTimeGroup: OutletOpeningTimeGroupUtil,
  locale: string,
): string {
  return `${mapWeekDayToLabel(openingTimeGroup.weekDays[0], locale)}${
    openingTimeGroup.weekDays.length > 1
      ? ` - ${mapWeekDayToLabel(
          last(openingTimeGroup.weekDays) as number,
          locale,
        )}`
      : ''
  }`;
}

function mapWeekDayToLabel(weekDay: number, locale: string): string {
  return DateTime.local()
    .setLocale(locale)
    .set({ weekday: weekDay })
    .toFormat('EEE');
}

export function isOpened(
  openingTimes: OutletOpeningTimeEntryUtil[],
  locale: Locale,
  timezone?: string,
  openingTimesExceptions?: OutletOpeningTimeExceptionEntryUtil[],
) {
  const currentDate = DateTime.now().setLocale(locale).setZone(timezone);

  const currentOpeningTimes = openingTimes.find(
    ({ weekDay }) => weekDay === currentDate.weekday,
  );

  if (!currentOpeningTimes) {
    return false;
  }

  const openingDate = DateTime.fromISO(currentOpeningTimes.openingTime)
    .setLocale(locale)
    .setZone(timezone);
  const closingDate = DateTime.fromISO(currentOpeningTimes.closingTime)
    .setLocale(locale)
    .setZone(timezone);

  const openInterval = Interval.fromDateTimes(openingDate, closingDate);

  if (!openInterval.contains(currentDate)) {
    return false;
  }

  if (!openingTimesExceptions) {
    return true;
  }

  const exceptionDates = openingTimesExceptions.map(({ date }) =>
    DateTime.fromISO(date).setLocale(locale).setZone(timezone),
  );

  return !(
    !isEmpty(exceptionDates) &&
    exceptionDates.some(
      (exceptionDate) => exceptionDate.toISODate() === currentDate.toISODate(),
    )
  );
}
